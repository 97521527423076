<template>
  <div class="text-lg mb-3 tc-gray-900  font-medium py-3 border-bottom flex items-center">
    <div class="left-bar"></div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>
@import '~@/styles/base.styl'
.left-bar
  width:4px
  height:16px
  margin-right 10px
  background-color: $primary
  border-radius:2px
</style>