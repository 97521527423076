var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "text-lg mb-3 tc-gray-900 font-medium py-3 border-bottom flex items-center",
    },
    [
      _c("div", { staticClass: "left-bar" }),
      _c("div", { staticClass: "flex-1" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }